import React from 'react'
import '../css/site.css';
import '../css/about.css'

export default function Navigation() {
    return (
        <section className="small-section">
            <h1>About</h1>
            <div className="box">
                <img id="chibi" src="/images/zebs_outline.png" alt="chibi allison" />
                <div>
                    <p>
                        Hello! I'm Allison, and I make crochets, knit, and learning sewing~
                        <br />
                        <br />
                        Some facts about me are:
                        <br />
                        <ul>
                            <li>
                                I just graduated from college and started my first job!
                            </li>
                            <li>
                                I really like drawing "dangos" hence the name Dango Ploof~
                            </li>
                            <li>
                                I really like stuffed animals which is what got me into crocheting at first :)
                            </li>
                            <li>
                                I started crocheting in 2020! Crazy it has been 4 years now! Cheers to many more
                            </li>
                            <li>
                                Some of my favorite crochets are my favorite game characters, but recently I have made some nice clothing pieces I love.
                            </li>
                            <li>
                                I recently started knitting and now am looking to try sewing some cosplays too!
                            </li>
                        </ul>
                        <br />
                        I hope to keep crocheting, knitting, and sewing as fun hobbies for myself, and I hope to make others happy with my crafts. :3
                    </p>
                </div>
            </div>
        </section>
    );
}