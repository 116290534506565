import React from 'react'
import '../css/site.css';
import '../css/about.css'

import pattern_data from '../data/patterns.json'

export default function Patterns() {
    return (
        <section className="small-section">
            <h1>Free Patterns</h1>
            <div className="box">
            <img id="doodleson" src="/images/allisondoodle.png" alt="allison with crochets"></img>
                <div>
                    <div className='card-row'>
                        {
                            pattern_data.map( (pattern) => (
                                <div className="card">
                                    <a className='card-link' href={pattern.link}>
                                    <img className="card-img" src={pattern.img} alt={pattern.name}></img>
                                    <h2>{pattern.name}</h2>
                                    <p>{pattern.description}</p>
                                    </a>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}