//import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './css/App.css';
import './css/site.css';
import './css/index.css';

import Navigation from './components/Navigation'
import Footer from './components/Footer'

import Home from './components/Home'
import About from './components/About'
import Gallery from './components/Gallery'
import Commissions from './components/Commissions';
import Patterns from './components/Patterns';
import Choncc from './FreePatterns/Choncc';
import Poro from './FreePatterns/Poro';
import Dowsie from './FreePatterns/Dowsie';
import DangoTFT from './FreePatterns/DangoTFT';

function App() {
  return (
    <Router>
      <Navigation /> 
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route path='/about' element={<About/>} />
        <Route path='/gallery' element={<Gallery/>} />
        <Route path='/commissions' element={<Commissions/>} />
        <Route path='/patterns' element={<Patterns/>} />
        <Route path='/patterns/choncc' element={<Choncc />} />
        <Route path='/patterns/poro' element={<Poro />} />
        <Route path='/patterns/dowsie' element={<Dowsie />} />
        <Route path='/patterns/dangoTFT' element={<DangoTFT />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
