import React from 'react'

export default function Dowsie() {
    return (
        <section className="small-section">
            <h2>Dowsie Pattern</h2>
            <img width="30%" src="/images/patterns/dowsie/dowsie.jpg" alt="dowsie"></img>
            <p>Please do let me know if you notice any mistakes or typos! (An Instagram dm or email will work!)</p>
            <p>This pattern makes the basic dowsie, but changing the colors and adding some accessories can make other variations! <br />
                For example the pictures shown is the colors of the victorious dowsie variant! <br />
                This is where the Body blue = purple instead of blue and pink = dark purple/gold for the scarf and wand.</p>
            <h3>Yarn needed:</h3>
            <p>
                <ul>
                    <li>Blue (Hat Blue)</li> 
                    <li>Light blue (Body Blue)</li>
                    <li>White</li>
                    <li>Pink</li>
                    <li>Brown</li>
                </ul>
            </p>
            <h3>Other Materials:</h3>
            <p>
                <ul>
                    <li>Needle felting needle</li>
                    <li>Felt in aqua, black, and white (for the eyes)</li>
                </ul>
                Note: you can just cut out felt pieces for the eyes instead of needle felting it
            </p>
            <h3>Abbreviations: </h3>
            <p>
                <ul>
                    <li>sc = single crochet</li>
                    <li>mr = magic ring</li>
                    <li>inc = increase</li>
                    <li>dec = decrease</li>
                    <li>flo = front loop only</li>
                </ul>

            </p>

            <h3>Hat</h3>
            <p>
                Start with Hat Blue<br />
                Round 1: 6 sc in a mr (6) <br />
                Round 2: (1 sc, 1 inc)* repeat around (9) <br />
                Round 3: 1 sc around (9) <br />
                Round 4: (2sc, 1 inc) (12) <br />
                Round 5: (3sc, 1 inc) (15) <br />
                Round 6-8: 1 sc around (15) <br />
                Round 9: 7 sc, 1 dec, 6 sc (14) <br />
                Round 10-13: 1 sc around (14) <br />
                Round 13: (3 sc, inc, 2 sc, inc)* 2 (18) <br />
                Round 14: 1 sc around (18) <br />
                Round 15: (flo) (2 sc, inc)* repeat around (24) <br />
                Round 16: (3 sc, inc)* repeat around (30) <br />
                Round 17: (4 sc, inc)* repeat around (36) <br />
                Round 18: (5 sc, inc)* repeat around (42) <br />
                Round 19: (6 sc, inc)* repeat around (48) <br />
                Round 20: (7 sc, inc)* repeat around (54) <br />
                Round 21: (8 sc, inc)* repeat around (60) <br />
                Round 22: (9 sc, inc)* repeat around (66) <br />
                Round 23: (10 sc, inc)* repeat around (72) <br />
                Round 24: (11 sc, inc)* repeat around (78) <br />
                Cut and fasten off! Sew in the ends <br />
            </p>

            <h3>Head</h3>
            <p>
                Start with Body Blue <br />
                Round 1: 6 sc in a mr (6) <br />
                Round 2: (1 inc)* repeat around (12) <br />
                Round 3: (1 sc, inc)* repeat around (18) <br />
                Round 4: (2 sc, 1 inc)* repeat around (24) <br />
                Round 5: (3 sc, 1 inc)* repeat around (30) <br />
                Round 6: (4 sc, 1 inc)* repeat around (36) <br />
                Round 7-9: 1 sc around (36) <br />
                Round 10: 14 sc, <b>switch to white</b>, 8 sc, <b>switch to body blue</b>, 14 sc (36) <br />
                Round 11: 13 sc, <b>switch to white</b>, 10 sc, <b>switch to body blue</b>, 13 sc (36) <br />
                Round 12-14: 12 sc <b>switch to white</b>, 11 sc, <b>switch to body blue</b>, 12 sc (36) <br />
                Round 15: 2 sc, 1 dec, 4 sc, 1 dec, 3 sc, <b>switch to white</b>, 1 sc, 1 dec 4 sc, 1 dec, 1 sc, <b>switch to body blue</b>, 3 sc, 1 dec, 2 sc (30) <br />
                Round 16: 11 sc, <b>switch to white</b>, 9 sc, <b>switch to body blue</b>, 10 sc (30) <br />
                Round 17: (3 sc, 1 dec)* 2, 2 sc, <b>switch to white</b>, 1 sc, 1 dec, 3 sc, 1 dec, <b>switch to body blue</b>, (3 sc, 1 dec)* 2 (24) <br />
                Round 18: (2 sc, 1 dec)* 2, 2 sc, <b>switch to white</b>, 1 dec, 2 sc, 1 dec, <b>switch to body blue</b>, (2 sc, 1 dec)* 2 (18) <br />
                Round 19: (1 sc, 1 dec)* 3, <b>switch to white</b>, 1 sc, 1 dec, <b>switch to body blue</b>, (1 sc, 1 dec)* 2 (12) <br />
                Round 20: 6 dec (6)
                Fasten off and sew in the end
            </p>

            <h3>Body</h3>
            <p>
                Start with Body Blue <br />
                Round 1: 6 sc in a mr (6) <br />
                Round 2: (1 sc, 1 inc)* 3 (9) <br />
                Round 3: 2 sc, 1 inc, <b>switch to white</b>, 2 sc, 1 inc <b>switch to body blue</b>, 2 sc, 1 inc (12) <br />
                Round 4: 3 sc, 1 inc, <b>switch to white</b>, 3 sc, 1 inc <b>switch to body blue</b>, 3 sc, 1 inc (15) <br />
                Round 5: 2 sc, 1 inc, 1 sc, <b>switch to white</b>, 3 sc, 1 inc, 3 sc, <b>switch to body blue</b>, 1 sc, 1 inc, 2 sc (18) <br />
                Round 6: 6 sc, 1 sc, <b>switch to white</b>, 1 sc in the previous stitch (like an inc with 2 colors), 5 sc, 1 inc, 1 sc, <b>switch to body blue</b>, 4 sc, 1 inc (21) <br />
                Round 7: 3 sc, 1 inc, 2 sc, <b>switch to white</b>, 4 sc, 1 inc, 4 sc, <b>switch to body blue</b>, 2 sc, 1 inc, 3 sc (24) <br />
                Round 8: 7 sc, <b>switch to white</b>, 1 inc, 7 sc, 1 inc, 1 sc, <b>switch to body blue</b>, 6 sc, 1 inc (27) <br />
                Round 9-12: 7 sc, <b>switch to white</b>, 12 sc, <b>switch to body blue</b>, 8 sc (27) <br />
                Round 13-17: 8 sc, <b>switch to white</b>, 12 sc, <b>switch to body blue</b>, 7 sc (27) <br />
                Round 18-19: 9 sc, <b>switch to white</b>, 12 sc, <b>switch to body blue</b>, 6 sc (27) <br />
                Round 20-21: 10 sc, <b>switch to white</b>, 12 sc, <b>switch to body blue</b>, 5 sc (27) <br />
                Round 22: 7 sc, 1 dec, 1 sc, <b>switch to white</b>, 6 sc, 1 dec, 2 sc, <b>switch to body blue</b>, 5 sc, 1 dec (24) <br />
                Round 23: (2 sc, 1 dec)* 3, <b>switch to white</b>, 2 sc, 1 dec, 2 sc, <b>switch to body blue</b>, 1 dec, 2 sc, 1 dec (18) <br />
                Fasted off with a long tail for sewing
            </p>

            <h3>Arms (x2)</h3>
            <p>
                Start with Body Blue <br />
                Round 1: 6 sc in a mr (6) <br />
                Round 2: 1 sc around (6) <br />
                Round 3: (1 sc, 1 inc)* 3 (9) <br />
                Round 4-7: 1 sc around (9) <br />
                Fasted off with a long tail for sewing
            </p>

            <h3>Ears (x2)</h3>
            <p>
                Start with Body Blue <br />
                Round 1: 6 sc in a mr (6) <br />
                Round 2: 6 inc (12) <br />
                Round 3: (1 sc, 1 inc)* 6 (18) <br />
                Round 4: (2 sc, 1 inc)* 6 (24) <br />
                Round 5-10: 1 sc around (24) <br />
                Change to pink <br />
                Round 12: 1 sc around (24) <br />
                Round 13: (2 sc, 1 dec)* 6 (18) <br />
                Round 14: 1 sc around (18) <br />
                Round 15: (1 sc, 1 dec)* 6 (12) <br />
                Round 16: 6 dec (6) <br />
                Fasten off with a long tail for sewing
            </p>

            <h3>Head curl</h3>
            <p>
                Start with Body Blue <br />
                Round 1: 6 sc in a mr (6) <br />
                Round 2-14: 1 sc around (6) <br />
            </p>

            <h3>Tail Fins (x2)</h3>
            <p>
                Start with Body Blue <br />
                Round 1: 6 sc in a mr (6) <br />
                Round 2: 1 sc around (6) <br />
                Round 3: (1 sc, 1 inc)* 3 (9) <br />
                Round 4: (2 sc, 1 inc)* 3 (12) <br />
                Round 5: 1 sc around (12) <br />
                Round 6: (2 sc, 1 dec)* 3 (9) <br />
                Round 7: (1 sc, 1 dec) * 3 (b) <br />
                Fasten off with a long tail for sewing
            </p>

            <h3>Wand Stick</h3>
            <p>
                Start with brown <br />
                Round 1: 6 sc in a mr (6) <br />
                Round 2-22: 1 sc around (6) <br />
            </p>

            <h3>Wand Star (x2)</h3>
            <p>
                Start with Pink <br />
                Round 1: 5 sc in a mr (5) <br />
                Round 2: 5 inc (10) <br />
                Round 3: (1 sc, 1 inc)* 5 (15) <br />
                Round 4: (1 slst, [1 hdc, 1 dc, 1 tr, 1 dc, 1 hdc] in one stitch) * 5, slst to start
                Fasten off and leave tail on one of the stars
            </p>

            <h3>Scarf</h3>
            <p>
                Start with Pink <br />
                Round 1: ch 31 <br />
                Round 2: 30 sc on ch <br />
                Round 3: 7 slst, 15 sc, turn <br />
                Round 4: 1 sc, dec, 9 sc, dec, 1 sc <br />
                Round 5: 1 sc, dec, 7 sc, dec, 1 sc <br />
                Round 6: 1 sc, dec, 5 sc, dec, 1 sc <br />
                Round 7: 1 sc, dec, 3 sc, dec, 1 sc <br />
                Round 8: dec, 3 sc, dec <br />
                Round 9: dec, sc <br />
                Round 10: dec <br />
                Fasten off and weave in the end
            </p>

            <h2>Assembly</h2>
            <ol>
                <li>Sew the head onto the body</li>
                <li>Sew the fins onto the bottom of the body</li>
                <li>Sew the arms on the side of the body</li>
                <li>Take the ears and hair curl, and curl them as much as you want. Glue or sew them into place.</li>
                <li>Attach the ears onto the head</li>
                <li>Attach the head curl onto the head</li>
                <li>Attach the hat such that it hides where the ears are attached</li>
                <li>Take the 2 star pieces and sew them together, making sure to stuff it a little</li>
                <li>Sew the star onto the wand stick</li>
                <li>Sew the wand into dowsie's right hand</li>
                <li>Put on the scarp and sew/tie it closed</li>
                <li>Use felt and needle felt the eyes on, refer to images below</li>
                <li>Sew on the mouth</li>
            </ol>
            <h3>Needle felting the eyes</h3>
            <p>
                a. <br /> <img width="20%" src="/images/patterns/dowsie/a.jpg" alt="base aqua"></img> 
                <br />
                b. <br /> <img width="20%" src="/images/patterns/dowsie/b.jpg" alt="pupils"></img>
                <br />
                c. <br /> <img width="20%" src="/images/patterns/dowsie/c.jpg" alt="whites"></img>
            </p>

            <h3>Congrats on finishing!!! </h3>
            <p><img width="30%" src="/images/patterns/dowsie/dowsie-pew.jpg" alt="dowsie"></img> <br />
                Tag: @dango.ploof on insta to show me your Dowsies!!</p>
            <div className="patterns">
                <a href="/patterns">Back to Patterns</a>
            </div>
        </section>
    );
}