import React from 'react'

export default function Poro() {
    return(
    <section className="small-section">
        <h1>Poro Pattern</h1>
        <img width="30%"src="/images/patterns/poro/poro.jpg" alt="poro"></img>
        <h3>Materials</h3> 
        <p>(For the thick yarn, I used Bernat Blanket Yarn, and for the thinner yarn, I used acrylic)<br></br>
            <ul>
                <li>White thick yarn</li>
                <li>Brown thick yarn</li>
                <li>Black thin yarn</li>
                <li>Pink thin yarn</li>
                <li>Brown thin yarn</li>
                <li>Safety Eyes</li>
            </ul>    
        </p> 
        <h3>Abbreviations</h3>
          <ul>
            <li>sc = single crochet</li>
            <li>dc = double crochet</li>
            <li>inc = increase</li>
            <li>dec = decrease</li>
            <li>mr = magic ring</li>
          </ul>
        <h2>With Blanket Yarn (thicker yarn)</h2>
        <h3>Body (in white)</h3>
        <p>Round 1: 8 sc in mr (8) <br></br>
Round 2: 8 inc (16) <br></br>
Round 3: [1sc, inc] * 8 (24) <br></br>
Round 4: [2 sc, inc] * 8 (32) <br></br>
Round 5: [3 sc, inc] * 8 (40) <br></br>
Round 6: 40 sc (40) <br></br>
Round 7: 2 sc, inc, [4 sc, inc] * 7, 2 sc (48) <br></br>
Round 8-14: 48 sc (48) <br></br>
Round 15: 2 sc, dec, [4 sc, dec] * 7, 2 sc (40) <br></br>
Round 16: [3 sc, dec] * 8 (32) <br></br>
Round 17: [2 sc, dec] * 8 (24) <br></br>
Round 18: [1 sc, dec] * 8 (16) <br></br>
Round 19: 8 dec (8) <br></br>
Fasten off</p>

        <h3>Legs (x4)</h3>
        <p>
            <b>(In Brown)</b> <br></br>
            Round 1: 6 sc in mr(6)  <br></br>
            <b>(Switch to White)</b>  <br></br>
            Round 2: 6 sc (6)  <br></br>
Fasten off, leave a tail for sewing 
        </p>

        <h2>Now with Acrylic Yarn (thinner yarn)</h2>
        <h3>Tongue (in Pink)</h3>
        <p>
        Round 1: 6 sc in mr (6) <br></br>
Round 2: 6 inc (12) <br></br>
Round 3: [1 sc, inc] * 6 (18) <br></br>
Round 4: [2 sc, inc] * 6 (24) <br></br>
Round 5-6: 24 sc (24) <br></br>
Round 7: [10 sc, dec] * 2 (22) <br></br>
Round 8: [9 sc, dec] * 2 (20) <br></br>
Round 8: [8sc, dec] * 2 (18) <br></br>
Round 9-13 : 18 sc (18) <br></br>
Fasten off, leave a tail for sewing
        </p>
        <h3>Horns (in Brown)</h3>
        <p>
        Round 1: 6 sc in mr (6)
Round 2: 6 inc (12)
Round 3: [1 sc, inc] * 6 (18)
Round 4-6: 18 sc (18)
Fasten off, leave a tail for sewing
        </p>
        <h3>Mustache</h3>
        <p className="patterns"> I used a free pattern here:  <br></br>
<a href="https://mamachee.com/2011/02/19/crochet-mustache-pattern">Get that Stache</a></p>

<h2>Assembly</h2>
<p className="patternAssembly">
    <img width ="50%" src = "/images/patterns/poro/poroassembly.png" alt="assembly instructions"></img>
</p>
<h3>Congrats on finishing!!! </h3>
<p>
Tag: @dango.ploof on insta to show me your poros!!</p>

<div className="patterns">
                <a href="/patterns">Back to Patterns</a>
            </div>
    </section>
    );
}