import React from "react";
import Popup from "reactjs-popup";
import "../css/site.css";

export default function Photo(props) {
  return (
    //ideally make thumbnails one day so it loads faster

    <div className="card" >
      <Popup
        trigger={() => (<img className="card-img" src={props.data.src} alt={props.data.title}   />)}
        position='center center'
        nested
        className="modal"
      >     <div className="popup max-width-600">
          <h2>{props.data.title}</h2>
          <img className="popup-img" src={props.data.src} alt={props.data.title} />
          <p>Date created: {props.data.date}</p>
        </div>
      </Popup>
      <p>{props.data.title}<br />
        {props.data.date}</p>
    </div>
  );
}
