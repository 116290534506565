import React from 'react'
import '../css/site.css';
import OrdersList from './OrdersList'
//import { useNavigate } from "react-router-dom";

export default function Home() {
  
    return(
        <div>
        <section className="welcome">
        <div className="welcome-content" style={{ borderRadius: 20 }}>
            <div className="non-transparent" style={{ opacity: 1 }}>
                <h1>Dango Ploof Crochets</h1>
                <section>
                    <h2>❤ Ploofs of love</h2>
                    <p>Hi! My name is Allison, and I love making crochet. I've been crocheting for about 4 years and love making amigurumi. Recently I started making clothes too!</p>
                </section>
                <section className="column">
                    <div className="button-row">
                        <a href="commissions"><button className="button-green">Commission Now</button></a>
                    </div>
                    <div className="button-row">
                        {/* <a href="/commissions"><button className="button">Customs</button></a> */}
                        <a href="https://www.etsy.com/shop/DangoPloof"><button className="button">Etsy (Patterns)</button></a>
                        <a href="https://www.instagram.com/dango.ploof/"><button className="button">Instagram</button></a>
                        {/* <button className="button" onClick= {routeChange("/shop")}>Shop</button> */}
                    </div>
                </section>
            </div>
        </div>
      </section>
      <section className="featured section">
        <OrdersList />
      </section>
    </div>
    );
}