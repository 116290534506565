import React from "react";
import Popup from "reactjs-popup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../css/site.css";

import "../css/App.css";
import { useState } from "react";
import { send } from "emailjs-com";

export default function Commissions() {
  const [toSend, setToSend] = useState({
    from_name: "",
    description: "",
    size: "Small",
    phone: "",
    email: "",
    contact: "Email",
    message: "",
    reply_to: "",
  });

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    send("service_g55bqpf", "template_btal8kg", toSend, "kbyjIjOhpzvJSaFlq")
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        toast.success("Successfully submitted!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setToSend({
          from_name: "",
          description: "",
          size: "Small",
          phone: "",
          email: "",
          contact: "Email",
          message: "",
          reply_to: "",
        });
      })
      .catch((err) => {
        console.log("FAILED...", err);
        toast.error("Error with submission", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  return (
    <>
      {" "}
      <section className="small-section">
        <h1>Commissions</h1>
        <p>
          Thank you so much for considering a comission from me!
          <br></br>
          <br></br>
          <strong>Currently my customs are: Open!</strong>
        </p>
        <ol>
          <li>Cloud Bear</li>
          <li></li>
          <li></li>
          <li></li>
        </ol>
      </section>
      <section className="small-section">
        <h2>How to Order:</h2>
        <p>
          Please fill out the form below to order! I then will contact you (by
          email, unless preference stated otherwise) to start the order and work
          out details, payment, delivery, etc.
          <br></br>
          <br></br>
          <em>
            It is very important that you fill out your contact information with
            something you will reply to since this is how I will work out your
            order and ask questions if need be!
          </em>
          <br />
          <br></br>
          <b>Payment: </b> Venmo or Paypal
          <br></br>
          <br></br>
          For updates on an order I am working on, you can look at the queue
          here or on the front page!
        </p>
      </section>
      <section className="small-section">
        <Popup
          trigger={(open) => (
            <button className="button">{open ? "Hide" : "View"} Form</button>
          )}
          modal
          nested
          className="modal"
        >
          <div className="popup white-bg">
            <h2>Form</h2>
            {/* <p>Currently, my commissions are closed, come back another time and fill out the form for a quote.</p> */}
            <span>* signifies a required field</span>
            <br />
            <span>Note: form responses are saved when the form is closed</span>

            <form onSubmit={onSubmit}>
              <div className="popup-row">
                <div className="popup-col">
                  <h4>Crochet Information</h4>
                  <div>
                    <label htmlFor="name">Name*: </label>
                    <input
                      type="text"
                      id="name"
                      name="from_name"
                      placeholder="Your name"
                      value={toSend.from_name}
                      onChange={handleChange}
                      required="required"
                    />
                  </div>
                  <div>
                    <label htmlFor="description">Description*: </label>
                    <br></br>
                    <textarea
                      type="text"
                      name="description"
                      id="description"
                      placeholder="Description of commission"
                      value={toSend.description}
                      onChange={handleChange}
                      rows="4"
                      cols="40"
                      required="required"
                    />
                  </div>
                  <div onChange={handleChange}>
                    <label htmlFor="size">Size*: </label>
                    <br />
                    <input type="radio" id="mini" name="size" value="Mini" />
                    <label htmlFor="mini">Mini</label>
                    <br />
                    <input
                      type="radio"
                      id="small"
                      name="size"
                      value="Small"
                      defaultChecked
                    />
                    <label htmlFor="small">Small</label>
                    <br />
                    <input
                      type="radio"
                      id="medium"
                      name="size"
                      value="Medium"
                    />
                    <label htmlFor="medium">Medium</label>
                    <br />
                    <input type="radio" id="jumbo" name="size" value="Jumbo" />
                    <label htmlFor="jumbo">Jumbo</label>
                    <br />
                  </div>
                </div>

                <div className="popup-col">
                  <h4>Contact Information</h4>
                  <div>
                    <label htmlFor="email">Email*: </label>
                    <input
                      type="email"
                      name="reply_to"
                      placeholder="abc@xyz.com"
                      id="email"
                      value={toSend.reply_to}
                      onChange={handleChange}
                      required="required"
                    />
                  </div>
                  <div>
                    <label htmlFor="phone">Phone: </label>
                    <input
                      type="input"
                      name="phone"
                      id="phone"
                      placeholder="000 000 0000"
                      value={toSend.phone}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="size">Perferred Method of Contact*: </label>
                    <br />
                    <input
                      type="radio"
                      id="email"
                      name="contact"
                      value="email"
                      defaultChecked
                    />
                    <label htmlFor="email">Email</label>
                    <br></br>
                    <input
                      type="radio"
                      id="phone"
                      name="contact"
                      value="phone"
                    />
                    <label htmlFor="phone">Phone</label>
                    <br />
                  </div>
                  <div>
                    <label htmlFor="message">Additional Notes: </label>
                    <br></br>
                    <textarea
                      type="text"
                      name="message"
                      id="message"
                      placeholder="Comments"
                      value={toSend.message}
                      onChange={handleChange}
                      rows="4"
                      cols="40"
                    />
                  </div>
                </div>
              </div>
              <button className="button" type="submit">
                Submit
              </button>
            </form>
          </div>
        </Popup>
      </section>
      <section className="small-section">
        <h2>Sizes & Prices</h2>
        <table>
          <tbody>
            <tr>
              <td>
                <img src="images/mini.jpg" alt="mini ploof" />
              </td>
              <td>
                <img src="images/small.jpg" alt="small ploof" />
              </td>
              <td>
                <img src="images/medium.jpg" alt="medium ploof" />
              </td>
              <td>
                <img src="images/jumbo.jpg" alt="jumbo ploof" />
              </td>
            </tr>
            <tr>
              <td>Mini Ploof</td>
              <td>Small Ploof</td>
              <td>Medium Ploof</td>
              <td>Jumbo Ploof</td>
            </tr>
            <tr>
              <td>~2"x2"x2"</td>
              <td>~3"x3"x3"</td>
              <td>~5"x5"x5"</td>
              <td>~9"x9"x9"+!</td>
            </tr>
            <tr>
              <td>$7-10</td>
              <td>$20-40</td>
              <td>$60-90</td>
              <td>$90+</td>
            </tr>
          </tbody>
        </table>
      </section>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
