import React from "react";
import "../css/site.css";
import gallery_data from "../data/gallery.json";
import Photo from './Photo.js'

export default function Gallery() {
  //need to change this to take in an array for the custom orders and status like a state machine
  return (
    <section className="small-section">
        <h1>Gallery</h1>
        <div className="card-row">
            {gallery_data.map((item) => (
                <Photo key={item.title} data={item} />
            ))}
        </div>
    </section>
  );
}
