import React from 'react'
import '../css/site.css';

export default function Navigation() {
    return(
    <footer>
    <div className="auto-align">
        <h2>Stay connected!</h2>

        <ul className="contact-list">
            {/* <li><img id="dango" src="images/dango_fin.png" alt="Dango logo" height="60px"/></li> */}
            <li><img id="footer-img" src="/images/email.png" alt="email icon" height="20px"/><a href="mailto:dangoploof@gmail.com">dangoploof@gmail.com</a></li>
            <li><img id="footer-img" src="/images/instagram.png" alt="instagram logo" height="20px"/><a href="https://www.instagram.com/dango.ploof/">@dango.ploof</a></li>
        </ul>
    </div>
    </footer>
    )
}