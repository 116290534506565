import React from 'react'
import { Link } from 'react-router-dom';
import '../css/site.css';

export default function Navigation() {
    return(
        <header>
        <div className="navigation-bar  sticky">
            <p className="navigation-item"><Link to="/about">About</Link></p>
            <p className="navigation-item"><Link to="/patterns">Free Patterns</Link></p>
            {/* <div className="navigation-item"><p><a href="shop">Shop</a></p></div> */}
            <div className="navigation-item"><Link to="/"><img id="dango" src="/images/dango_fin.png" alt="Dango logo"/></Link></div>
            <p className="navigation-item"><Link to="commissions">Commissions</Link></p>
            <p className="navigation-item"><Link to="gallery">Gallery</Link></p>
            {/* <div className="navigation-item"><p><a href="pay">Cart</a></p></div> */}
        </div>
        </header>
    );
}